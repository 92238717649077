@import-normalize;

@font-face {
  font-family:genesis-text;
  font-weight: 400;
  font-display: block;
  src:url('../assets/fonts/GenesisSansTextKRRegular.woff2') format('woff2'),
      url('../assets/fonts/GenesisSansTextKRRegular.woff') format('woff');
}

@font-face {
  font-family:genesis-text;
  font-weight: 500;
  font-display: block;
  src:url('../assets/fonts/GenesisSansTextKRMedium.woff2') format('woff2'),
      url('../assets/fonts/GenesisSansTextKRMedium.woff') format('woff');
}

@font-face {
  font-family:genesis-text;
  font-weight: 700;
  font-display: block;
  src:url('../assets/fonts/GenesisSansTextKRBold.woff2') format('woff2'),
      url('../assets/fonts/GenesisSansTextKRBold.woff') format('woff');
}

@font-face {
  font-family:genesis-head;
  font-weight: 400;
  font-display: block;
  src:url('../assets/fonts/GenesisSansHeadKRRegular.woff2') format('woff2'),
      url('../assets/fonts/GenesisSansHeadKRRegular.woff') format('woff');
}

@font-face {
  font-family:genesis-head;
  font-weight: 300;
  font-display: block;
  src:url('../assets/fonts/GenesisSansHeadKRLight.woff2') format('woff2'),
      url('../assets/fonts/GenesisSansHeadKRLight.woff') format('woff');
}

@font-face {
  font-family:genesis-text-medium;
  font-display: block;
  src:url('../assets/fonts/GenesisSansTextKRMedium.woff2') format('woff2'),
      url('../assets/fonts/GenesisSansTextKRMedium.woff') format('woff');
}

@font-face {
  font-family:genesis-text-regular;
  font-display: block;
  src:url('../assets/fonts/GenesisSansHeadKRRegular.woff2') format('woff2'),
      url('../assets/fonts/GenesisSansHeadKRRegular.woff') format('woff');
}

@font-face {
  font-family:genesis-head-light;
  font-display: block;
  src:url('../assets/fonts/GenesisSansHeadKRLight.woff2') format('woff2'),
      url('../assets/fonts/GenesisSansHeadKRLight.woff') format('woff');
}


* {
  margin: 0;
  padding: 0;
}

* {
  animation-fill-mode: forwards;
}

*[style*="opacity: 0"] {
  pointer-events: none !important;
}

div[style*="opacity: 0"] * {
  pointer-events: none !important;
}

fieldset,img {
  vertical-align: top;
  border: 0 none;
  min-width: 0;
  max-width: 100%;
}

dl,ul,ol,menu,li {
  list-style: none
}

blockquote,q {
  quotes: none
}

blockquote:before,blockquote:after,q:before,q:after {
  content: '';
  content: none
}

input,select,textarea,button {
  vertical-align: middle;
  -webkit-border-radius: 0;
  border-radius: 0;
}

label {
  cursor: pointer;
}

input::-ms-clear {
  display: none;
}

select::-ms-expand {
  display: none;
}

button {
  border: 0 none;
  border-radius: 0;
  background-color: transparent;
  cursor: pointer;
  outline: 0;
}

button {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

html {
  height: 100%;
  font-size: 16px;
}

body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-text-size-adjust: none;

  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  height: 100%;
  overflow: hidden;
}

main {
  position: absolute;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
}

body,th,td,input,select,textarea,button {
  font-family: genesis-text;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

a {
  text-decoration: none
}

a:active,a:hover {
  text-decoration: none
}

address,caption,cite,code,dfn,em,var {
  font-style: normal;
  font-weight: normal
}

table {
  border-spacing: 0;
}

h1, h2, h3, h4, strong, em {
  font-weight: 400;
}

div {
  pointer-events: none;
}

img, canvas, button {
  pointer-events: auto;
}

#root {
  position: absolute;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
}

#root-canvas {
  position: absolute;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
}
